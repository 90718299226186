import L from "axios";
var C = /\{[^{}]+\}/g, f = ({ allowReserved: t, name: e, value: a }) => {
  if (a == null) return "";
  if (typeof a == "object") throw new Error("Deeply-nested arrays/objects aren’t supported. Provide your own `querySerializer()` to handle these.");
  return `${e}=${t ? a : encodeURIComponent(a)}`;
}, s = (t) => {
  switch (t) {
    case "label":
      return ".";
    case "matrix":
      return ";";
    case "simple":
      return ",";
    default:
      return "&";
  }
}, O = (t) => {
  switch (t) {
    case "form":
      return ",";
    case "pipeDelimited":
      return "|";
    case "spaceDelimited":
      return "%20";
    default:
      return ",";
  }
}, B = (t) => {
  switch (t) {
    case "label":
      return ".";
    case "matrix":
      return ";";
    case "simple":
      return ",";
    default:
      return "&";
  }
}, w = ({ allowReserved: t, explode: e, name: a, style: u, value: d }) => {
  if (!e) {
    let c = (t ? d : d.map((g) => encodeURIComponent(g))).join(O(u));
    switch (u) {
      case "label":
        return `.${c}`;
      case "matrix":
        return `;${a}=${c}`;
      case "simple":
        return c;
      default:
        return `${a}=${c}`;
    }
  }
  let m = s(u), l = d.map((c) => u === "label" || u === "simple" ? t ? c : encodeURIComponent(c) : f({ allowReserved: t, name: a, value: c })).join(m);
  return u === "label" || u === "matrix" ? m + l : l;
}, A = ({ allowReserved: t, explode: e, name: a, style: u, value: d }) => {
  if (u !== "deepObject" && !e) {
    let c = [];
    Object.entries(d).forEach(([y, S]) => {
      c = [...c, y, t ? S : encodeURIComponent(S)];
    });
    let g = c.join(",");
    switch (u) {
      case "form":
        return `${a}=${g}`;
      case "label":
        return `.${g}`;
      case "matrix":
        return `;${a}=${g}`;
      default:
        return g;
    }
  }
  let m = B(u), l = Object.entries(d).map(([c, g]) => f({ allowReserved: t, name: u === "deepObject" ? `${a}[${c}]` : c, value: g })).join(m);
  return u === "label" || u === "matrix" ? m + l : l;
}, U = ({ path: t, url: e }) => {
  let a = e, u = e.match(C);
  if (u) for (let d of u) {
    let m = !1, l = d.substring(1, d.length - 1), c = "simple";
    l.endsWith("*") && (m = !0, l = l.substring(0, l.length - 1)), l.startsWith(".") ? (l = l.substring(1), c = "label") : l.startsWith(";") && (l = l.substring(1), c = "matrix");
    let g = t[l];
    if (g == null) continue;
    if (Array.isArray(g)) {
      a = a.replace(d, w({ explode: m, name: l, style: c, value: g }));
      continue;
    }
    if (typeof g == "object") {
      a = a.replace(d, A({ explode: m, name: l, style: c, value: g }));
      continue;
    }
    if (c === "matrix") {
      a = a.replace(d, `;${f({ name: l, value: g })}`);
      continue;
    }
    let y = encodeURIComponent(c === "label" ? `.${g}` : g);
    a = a.replace(d, y);
  }
  return a;
}, P = ({ path: t, url: e }) => t ? U({ path: t, url: e }) : e, D = (t, e, a) => {
  typeof a == "string" || a instanceof Blob ? t.append(e, a) : t.append(e, JSON.stringify(a));
}, b = (t, e) => {
  let a = { ...t, ...e };
  return a.headers = T(t.headers, e.headers), a;
}, T = (...t) => {
  let e = {};
  for (let a of t) {
    if (!a || typeof a != "object") continue;
    let u = Object.entries(a);
    for (let [d, m] of u) if (m === null) delete e[d];
    else if (Array.isArray(m)) for (let l of m) e[d] = [...e[d] ?? [], l];
    else m !== void 0 && (e[d] = typeof m == "object" ? JSON.stringify(m) : m);
  }
  return e;
}, v = { bodySerializer: (t) => {
  let e = new FormData();
  return Object.entries(t).forEach(([a, u]) => {
    u != null && (Array.isArray(u) ? u.forEach((d) => D(e, a, d)) : D(e, a, u));
  }), e;
} }, R = (t = {}) => ({ baseURL: "", ...t }), M = (t) => {
  let e = b(R(), t), a = L.create(e), u = () => ({ ...e }), d = (l) => (e = b(e, l), a.defaults = { ...a.defaults, ...e, headers: T(a.defaults.headers, e.headers) }, u()), m = async (l) => {
    var S;
    let c = { ...e, ...l, headers: T(e.headers, l.headers) };
    c.body && c.bodySerializer && (c.body = c.bodySerializer(c.body));
    let g = P({ path: c.path, url: c.url }), y = c.axios || a;
    try {
      let h = await y({ ...c, data: c.body, params: c.query, url: g }), { data: k } = h;
      return c.responseType === "json" && c.responseTransformer && (k = await c.responseTransformer(k)), { ...h, data: k ?? {} };
    } catch (h) {
      let k = h;
      if (c.throwOnError) throw k;
      return k.error = ((S = k.response) == null ? void 0 : S.data) ?? {}, k;
    }
  };
  return { delete: (l) => m({ ...l, method: "delete" }), get: (l) => m({ ...l, method: "get" }), getConfig: u, head: (l) => m({ ...l, method: "head" }), instance: a, options: (l) => m({ ...l, method: "options" }), patch: (l) => m({ ...l, method: "patch" }), post: (l) => m({ ...l, method: "post" }), put: (l) => m({ ...l, method: "put" }), request: m, setConfig: d };
};
const r = M(R());
class E {
  /**
   * 详情
   * 传入dataScope
   */
  static getTankSystemScopeDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/api-scope/detail"
    });
  }
  /**
   * 分页
   * 传入dataScope
   */
  static getTankSystemScopeList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/api-scope/list"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static postTankSystemScopeRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/api-scope/remove"
    });
  }
  /**
   * 新增
   * 传入dataScope
   */
  static postTankSystemScopeSave(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/api-scope/save"
    });
  }
  /**
   * 新增或修改
   * 传入dataScope
   */
  static postTankSystemScopeSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/api-scope/submit"
    });
  }
  /**
   * 修改
   * 传入dataScope
   */
  static postTankSystemScopeUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/api-scope/update"
    });
  }
}
class Y {
  /**
   * 详情
   * 传入attach
   */
  static getTankResourceAttachDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-resource/attach/detail"
    });
  }
  /**
   * 分页
   * 传入attach
   */
  static getTankResourceAttachList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-resource/attach/list"
    });
  }
  /**
   * 分页
   * 传入attach
   */
  static getTankResourceAttachPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-resource/attach/page"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static postTankResourceAttachRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/attach/remove"
    });
  }
  /**
   * 新增
   * 传入attach
   */
  static postTankResourceAttachSave(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/attach/save"
    });
  }
  /**
   * 新增或修改
   * 传入attach
   */
  static postTankResourceAttachSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/attach/submit"
    });
  }
  /**
   * 修改
   * 传入attach
   */
  static postTankResourceAttachUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/attach/update"
    });
  }
}
class F {
  /**
   * 导出任务文件下载
   */
  static getSysExportDownload(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/sys/export/download"
    });
  }
  /**
   * 任务导出列表
   */
  static getSysExportList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/sys/export/list"
    });
  }
}
class z {
  /**
   * 短信验证码发送接口
   * 传入手机号码
   */
  static postSmsSend(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/sms/send"
    });
  }
  /**
   * 短信验证码校验接口
   * 传入smsCheckDataDto
   */
  static postSmsValidate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/sms/validate"
    });
  }
}
class I {
  /**
   * 详情
   * 传入client
   */
  static getTankSystemClientDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/client/detail"
    });
  }
  /**
   * 分页
   * 传入client
   */
  static getTankSystemClientList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/client/list"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static postTankSystemClientRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/client/remove"
    });
  }
  /**
   * 新增
   * 传入client
   */
  static postTankSystemClientSave(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/client/save"
    });
  }
  /**
   * 下拉数据源
   */
  static getTankSystemClientSelect(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/client/select"
    });
  }
  /**
   * 新增或修改
   * 传入client
   */
  static postTankSystemClientSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/client/submit"
    });
  }
  /**
   * 修改
   * 传入client
   */
  static postTankSystemClientUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/client/update"
    });
  }
}
class W {
  /**
   * 线索-货主关联
   */
  static postLeadsOwnerAssociation(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/owner/association"
    });
  }
  /**
   * 线索-货主解绑
   */
  static postLeadsOwnerDisassociation(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/owner/disassociation"
    });
  }
}
class j {
  /**
   * billInfoSync
   */
  static getBillSync(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/bill/sync"
    });
  }
  /**
   * crmSendTodoJob
   */
  static postSendTodo(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/send/todo"
    });
  }
  /**
   * crmTimeOutTodoJob
   */
  static postTimeoutTodo(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/timeout/todo"
    });
  }
}
class N {
  /**
   * 消息列表已读
   */
  static getMessageListRead(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/message/list/read"
    });
  }
  /**
   * 消息列表查询
   */
  static getMessagePage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/message/page"
    });
  }
  /**
   * 广场消息已读
   */
  static getMessageRead(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/message/read"
    });
  }
  /**
   * 全部已读
   */
  static getMessageReadAll(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/message/read/all"
    });
  }
  /**
   * 未读记录列表查询
   */
  static getMessageReadList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/message/read/list"
    });
  }
  /**
   * 待办列表查询
   */
  static getMessageTodoDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/message/todo/detail"
    });
  }
  /**
   * 待办列表查询
   */
  static getMessageTodoPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/message/todo/page"
    });
  }
}
class $ {
  /**
   * 详情
   * 传入datasource
   */
  static getTankDevelopDatasourceDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-develop/datasource/detail"
    });
  }
  /**
   * 分页
   * 传入datasource
   */
  static getTankDevelopDatasourceList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-develop/datasource/list"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static postTankDevelopDatasourceRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-develop/datasource/remove"
    });
  }
  /**
   * 新增
   * 传入datasource
   */
  static postTankDevelopDatasourceSave(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-develop/datasource/save"
    });
  }
  /**
   * 下拉数据源
   * 查询列表
   */
  static getTankDevelopDatasourceSelect(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-develop/datasource/select"
    });
  }
  /**
   * 新增或修改
   * 传入datasource
   */
  static postTankDevelopDatasourceSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-develop/datasource/submit"
    });
  }
  /**
   * 修改
   * 传入datasource
   */
  static postTankDevelopDatasourceUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-develop/datasource/update"
    });
  }
}
class q {
  /**
   * 详情
   * 传入dataScope
   */
  static getTankSystemDataScopeDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/data-scope/detail"
    });
  }
  /**
   * 分页
   * 传入dataScope
   */
  static getTankSystemDataScopeList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/data-scope/list"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static postTankSystemDataScopeRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/data-scope/remove"
    });
  }
  /**
   * 新增
   * 传入dataScope
   */
  static postTankSystemDataScopeSave(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/data-scope/save"
    });
  }
  /**
   * 新增或修改
   * 传入dataScope
   */
  static postTankSystemDataScopeSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/data-scope/submit"
    });
  }
  /**
   * 修改
   * 传入dataScope
   */
  static postTankSystemDataScopeUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/data-scope/update"
    });
  }
}
class Q {
  /**
   * 详情
   * 传入dept
   */
  static getTankSystemDeptDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dept/detail"
    });
  }
  /**
   * 同步钉钉组织部门接口
   * 传入ids
   */
  static postTankSystemDeptDingtalkSync(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/dept/dingtalk/sync"
    });
  }
  /**
   * 懒加载列表
   * 传入dept
   */
  static getTankSystemDeptLazyList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dept/lazy-list"
    });
  }
  /**
   * 懒加载树形结构
   * 树形结构
   */
  static getTankSystemDeptLazyTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dept/lazy-tree"
    });
  }
  /**
   * 列表
   * 传入dept
   */
  static getTankSystemDeptList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dept/list"
    });
  }
  /**
   * 删除
   * 传入ids
   */
  static postTankSystemDeptRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/dept/remove"
    });
  }
  /**
   * 新增或修改
   * 传入dept
   */
  static postTankSystemDeptSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/dept/submit"
    });
  }
  /**
   * 树形结构
   * 树形结构
   */
  static getTankSystemDeptTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dept/tree"
    });
  }
}
class V {
  /**
   * 列表
   * 传入dict
   */
  static getTankSystemDictChildList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict/child-list"
    });
  }
  /**
   * 详情
   * 传入dict
   */
  static getTankSystemDictDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict/detail"
    });
  }
  /**
   * 获取字典
   * 获取字典
   */
  static getTankSystemDictDictionary(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict/dictionary"
    });
  }
  /**
   * 获取字典树
   * 获取字典树
   */
  static getTankSystemDictDictionaryTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict/dictionary-tree"
    });
  }
  /**
   * 列表
   * 传入dict
   */
  static getTankSystemDictList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict/list"
    });
  }
  /**
   * 列表
   * 传入dict
   */
  static getTankSystemDictParentList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict/parent-list"
    });
  }
  /**
   * 树形结构
   * 树形结构
   */
  static getTankSystemDictParentTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict/parent-tree"
    });
  }
  /**
   * 删除
   * 传入ids
   */
  static postTankSystemDictRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/dict/remove"
    });
  }
  /**
   * submit
   */
  static postTankSystemDictSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/dict/submit"
    });
  }
  /**
   * 树形结构
   * 树形结构
   */
  static getTankSystemDictTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict/tree"
    });
  }
}
class G {
  /**
   * 列表
   * 传入dict
   */
  static getTankSystemDictBizChildList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict-biz/child-list"
    });
  }
  /**
   * 详情
   * 传入dict
   */
  static getTankSystemDictBizDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict-biz/detail"
    });
  }
  /**
   * 获取字典
   * 获取字典
   */
  static getTankSystemDictBizDictionary(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict-biz/dictionary"
    });
  }
  /**
   * 获取字典树
   * 获取字典树
   */
  static getTankSystemDictBizDictionaryTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict-biz/dictionary-tree"
    });
  }
  /**
   * 列表
   * 传入dict
   */
  static getTankSystemDictBizList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict-biz/list"
    });
  }
  /**
   * 列表
   * 传入dict
   */
  static getTankSystemDictBizParentList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict-biz/parent-list"
    });
  }
  /**
   * 树形结构
   * 树形结构
   */
  static getTankSystemDictBizParentTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict-biz/parent-tree"
    });
  }
  /**
   * 删除
   * 传入ids
   */
  static postTankSystemDictBizRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/dict-biz/remove"
    });
  }
  /**
   * 新增或修改
   * 传入dict
   */
  static postTankSystemDictBizSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/dict-biz/submit"
    });
  }
  /**
   * 树形结构
   * 树形结构
   */
  static getTankSystemDictBizTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/dict-biz/tree"
    });
  }
}
class J {
  /**
   * 获取验证码
   */
  static getTankAuthOauthCaptcha(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-auth/oauth/captcha"
    });
  }
  /**
   * 清除缓存
   */
  static getTankAuthOauthClearCache(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-auth/oauth/clear-cache"
    });
  }
  /**
   * 退出登录
   */
  static getTankAuthOauthLogout(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-auth/oauth/logout"
    });
  }
  /**
   * token刷新接口
   */
  static postTankAuthOauthRefreshToken(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-auth/oauth/refreshToken"
    });
  }
  /**
   * 获取认证令牌
   * 传入租户ID:tenantId,账号:account,密码:password
   */
  static postTankAuthOauthToken(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-auth/oauth/token"
    });
  }
}
class K {
  /**
   * sendValidate
   */
  static postMailEndpointSendValidate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/mail/endpoint/send-validate"
    });
  }
  /**
   * validateMessage
   */
  static postMailEndpointValidate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/mail/endpoint/validate"
    });
  }
}
class X {
  /**
   * 菜单的角色权限
   */
  static getTankSystemMenuAuthRoutes(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/auth-routes"
    });
  }
  /**
   * 前端按钮数据
   * 前端按钮数据
   */
  static getTankSystemMenuButtons(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/buttons"
    });
  }
  /**
   * 详情
   * 传入menu
   */
  static getTankSystemMenuDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/detail"
    });
  }
  /**
   * 顶部菜单树形结构
   * 顶部菜单树形结构
   */
  static getTankSystemMenuGrantTopTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/grant-top-tree"
    });
  }
  /**
   * 权限分配树形结构
   * 权限分配树形结构
   */
  static getTankSystemMenuGrantTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/grant-tree"
    });
  }
  /**
   * 懒加载列表
   * 传入menu
   */
  static getTankSystemMenuLazyList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/lazy-list"
    });
  }
  /**
   * 懒加载菜单列表
   * 传入menu
   */
  static getTankSystemMenuLazyMenuList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/lazy-menu-list"
    });
  }
  /**
   * 列表
   * 传入menu
   */
  static getTankSystemMenuList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/list"
    });
  }
  /**
   * 菜单列表
   * 传入menu
   */
  static getTankSystemMenuMenuList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/menu-list"
    });
  }
  /**
   * 删除
   * 传入ids
   */
  static postTankSystemMenuRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/menu/remove"
    });
  }
  /**
   * 角色所分配的树
   * 角色所分配的树
   */
  static getTankSystemMenuRoleTreeKeys(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/role-tree-keys"
    });
  }
  /**
   * 前端菜单数据
   * 前端菜单数据
   */
  static getTankSystemMenuRoutes(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/routes"
    });
  }
  /**
   * 前端菜单数据
   * 前端菜单数据
   */
  static getTankSystemMenuRoutesExt(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/routes-ext"
    });
  }
  /**
   * 新增或修改
   * 传入menu
   */
  static postTankSystemMenuSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/menu/submit"
    });
  }
  /**
   * 顶部菜单数据
   * 顶部菜单数据
   */
  static getTankSystemMenuTopMenu(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/top-menu"
    });
  }
  /**
   * 顶部菜单所分配的树
   * 顶部菜单所分配的树
   */
  static getTankSystemMenuTopTreeKeys(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/top-tree-keys"
    });
  }
  /**
   * 树形结构
   * 树形结构
   */
  static getTankSystemMenuTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/menu/tree"
    });
  }
}
class H {
  /**
   * 详情
   * 传入notice
   */
  static getTankDeskNoticeDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-desk/notice/detail"
    });
  }
  /**
   * 分页
   * 传入notice
   */
  static getTankDeskNoticeList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-desk/notice/list"
    });
  }
  /**
   * 分页
   * 传入notice
   */
  static getTankDeskNoticePage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-desk/notice/page"
    });
  }
  /**
   * 逻辑删除
   * 传入notice
   */
  static postTankDeskNoticeRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-desk/notice/remove"
    });
  }
  /**
   * 新增
   * 传入notice
   */
  static postTankDeskNoticeSave(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-desk/notice/save"
    });
  }
  /**
   * 新增或修改
   * 传入notice
   */
  static postTankDeskNoticeSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-desk/notice/submit"
    });
  }
  /**
   * 修改
   * 传入notice
   */
  static postTankDeskNoticeUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-desk/notice/update"
    });
  }
}
class Z {
  /**
   * copyFile
   */
  static postTankResourceOssEndpointCopyFile(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/oss/endpoint/copy-file"
    });
  }
  /**
   * delFile
   */
  static postTankResourceOssEndpointDelFile(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/oss/endpoint/del-file"
    });
  }
  /**
   * fileLink
   */
  static getTankResourceOssEndpointFileLink(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-resource/oss/endpoint/file-link"
    });
  }
  /**
   * filePath
   */
  static getTankResourceOssEndpointFilePath(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-resource/oss/endpoint/file-path"
    });
  }
  /**
   * makeBucket
   */
  static postTankResourceOssEndpointMakeBucket(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/oss/endpoint/make-bucket"
    });
  }
  /**
   * putFile
   */
  static postTankResourceOssEndpointPutFile(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      ...v,
      headers: {
        "Content-Type": null,
        ...e == null ? void 0 : e.headers
      },
      url: "/tank-resource/oss/endpoint/put-file"
    });
  }
  /**
   * putFileAttach
   */
  static postTankResourceOssEndpointPutFileAttach(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      ...v,
      headers: {
        "Content-Type": null,
        ...e == null ? void 0 : e.headers
      },
      url: "/tank-resource/oss/endpoint/put-file-attach"
    });
  }
  /**
   * putFileAttach
   */
  static postTankResourceOssEndpointPutFileAttachByName(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      ...v,
      headers: {
        "Content-Type": null,
        ...e == null ? void 0 : e.headers
      },
      url: "/tank-resource/oss/endpoint/put-file-attach-by-name"
    });
  }
  /**
   * putFile
   */
  static postTankResourceOssEndpointPutFileByName(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      ...v,
      headers: {
        "Content-Type": null,
        ...e == null ? void 0 : e.headers
      },
      url: "/tank-resource/oss/endpoint/put-file-by-name"
    });
  }
  /**
   * removeBucket
   */
  static postTankResourceOssEndpointRemoveBucket(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/oss/endpoint/remove-bucket"
    });
  }
  /**
   * removeFile
   */
  static postTankResourceOssEndpointRemoveFile(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/oss/endpoint/remove-file"
    });
  }
  /**
   * removeFiles
   */
  static postTankResourceOssEndpointRemoveFiles(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/oss/endpoint/remove-files"
    });
  }
  /**
   * statFile
   */
  static getTankResourceOssEndpointStatFile(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-resource/oss/endpoint/stat-file"
    });
  }
  /**
   * uploadFile
   */
  static postTankResourceOssEndpointUploadFile(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      ...v,
      headers: {
        "Content-Type": null,
        ...e == null ? void 0 : e.headers
      },
      url: "/tank-resource/oss/endpoint/upload-file"
    });
  }
}
class _ {
  /**
   * delFile
   */
  static postDelFile(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/del-file"
    });
  }
  /**
   * 附件上传接口
   */
  static postUploadFile(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      ...v,
      headers: {
        "Content-Type": null,
        ...e == null ? void 0 : e.headers
      },
      url: "/upload-file"
    });
  }
  /**
   * 附件上传接口
   */
  static postWorkorderOrderUploadFile(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      ...v,
      headers: {
        "Content-Type": null,
        ...e == null ? void 0 : e.headers
      },
      url: "/v1/workorder/order/upload-file"
    });
  }
}
class n {
  /**
   * 详情
   * 传入param
   */
  static getTankSystemParamDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/param/detail"
    });
  }
  /**
   * 分页
   * 传入param
   */
  static getTankSystemParamList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/param/list"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static postTankSystemParamRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/param/remove"
    });
  }
  /**
   * 新增或修改
   * 传入param
   */
  static postTankSystemParamSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/param/submit"
    });
  }
}
class i {
  /**
   * 详情
   * 传入post
   */
  static getTankSystemPostDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/post/detail"
    });
  }
  /**
   * 分页
   * 传入post
   */
  static getTankSystemPostList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/post/list"
    });
  }
  /**
   * 分页
   * 传入post
   */
  static getTankSystemPostPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/post/page"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static postTankSystemPostRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/post/remove"
    });
  }
  /**
   * 新增
   * 传入post
   */
  static postTankSystemPostSave(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/post/save"
    });
  }
  /**
   * 下拉数据源
   * 传入post
   */
  static getTankSystemPostSelect(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/post/select"
    });
  }
  /**
   * 新增或修改
   * 传入post
   */
  static postTankSystemPostSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/post/submit"
    });
  }
  /**
   * 修改
   * 传入post
   */
  static postTankSystemPostUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/post/update"
    });
  }
}
class p {
  /**
   * 详情
   */
  static getVersionCheck(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/version/check"
    });
  }
}
class o {
  /**
   * 清空缓存
   */
  static getDictClearCache(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/dict/clearCache"
    });
  }
  /**
   * 字典查询接口
   */
  static getDictDictionary(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/dict/dictionary"
    });
  }
}
class ee {
  /**
   * 擎仓云合同列表查询
   */
  static getFinanceContractList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/finance/contract/list"
    });
  }
  /**
   * 擎仓云实体货主列表查询
   */
  static getQcyOwnerGroupPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/qcy/owner/group/page"
    });
  }
  /**
   * 作业服务指标查询
   */
  static getQcyWorkIndexList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/qcy/work/index/list"
    });
  }
  /**
   * 作业服务指标查询(汇总)
   */
  static getQcyWorkIndexListTotal(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/qcy/work/index/list/total"
    });
  }
  /**
   * 作业统计报表导出
   */
  static postQcyWorkReportExport(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/qcy/work/report/export"
    });
  }
  /**
   * 作业统计报表查询
   */
  static postQcyWorkReportPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/qcy/work/report/page"
    });
  }
  /**
   * 作业服务错漏发工单查询
   */
  static getQcyWorkWorkorderLossList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/qcy/work/workorder/loss/list"
    });
  }
  /**
   * 查询仓库分区树数据
   */
  static getWarehouseAreaTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/warehouse/area/tree"
    });
  }
  /**
   * 库存容量查询
   */
  static getWarehouseCapacityLast(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/warehouse/capacity/last"
    });
  }
  /**
   * 库存容量列表查询
   */
  static getWarehouseCapacityLastList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/warehouse/capacity/last/list"
    });
  }
}
class re {
  /**
   * 详情
   * 传入region
   */
  static getTankSystemRegionDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/region/detail"
    });
  }
  /**
   * 导出行政区划
   * 传入user
   */
  static getTankSystemRegionExportRegion(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/region/export-region"
    });
  }
  /**
   * 导出模板
   */
  static getTankSystemRegionExportTemplate(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/region/export-template"
    });
  }
  /**
   * 导入行政区划
   * 传入excel
   */
  static postTankSystemRegionImportRegion(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      ...v,
      headers: {
        "Content-Type": null,
        ...e == null ? void 0 : e.headers
      },
      url: "/tank-system/region/import-region"
    });
  }
  /**
   * 懒加载列表
   * 传入menu
   */
  static getTankSystemRegionLazyList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/region/lazy-list"
    });
  }
  /**
   * 懒加载列表
   * 传入menu
   */
  static getTankSystemRegionLazyTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/region/lazy-tree"
    });
  }
  /**
   * 分页
   * 传入region
   */
  static getTankSystemRegionList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/region/list"
    });
  }
  /**
   * 删除
   * 传入主键
   */
  static postTankSystemRegionRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/region/remove"
    });
  }
  /**
   * 新增
   * 传入region
   */
  static postTankSystemRegionSave(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/region/save"
    });
  }
  /**
   * 下拉数据源
   * 传入tenant
   */
  static getTankSystemRegionSelect(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/region/select"
    });
  }
  /**
   * 新增或修改
   * 传入region
   */
  static postTankSystemRegionSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/region/submit"
    });
  }
  /**
   * 修改
   * 传入region
   */
  static postTankSystemRegionUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/region/update"
    });
  }
}
class te {
  /**
   * 详情
   * 传入role
   */
  static getTankSystemRoleDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/role/detail"
    });
  }
  /**
   * 权限设置
   * 传入roleId集合以及menuId集合
   */
  static postTankSystemRoleGrant(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/role/grant"
    });
  }
  /**
   * 列表
   * 传入role
   */
  static getTankSystemRoleList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/role/list"
    });
  }
  /**
   * 删除
   * 传入ids
   */
  static postTankSystemRoleRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/role/remove"
    });
  }
  /**
   * 新增或修改
   * 传入role
   */
  static postTankSystemRoleSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/role/submit"
    });
  }
  /**
   * 树形结构
   * 树形结构
   */
  static getTankSystemRoleTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/role/tree"
    });
  }
}
class ae {
  /**
   * sendMeeting
   */
  static postTankResourceSmsEndpointSendMeeting(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/sms/endpoint/send-meeting"
    });
  }
  /**
   * sendMessage
   */
  static postTankResourceSmsEndpointSendMessage(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/sms/endpoint/send-message"
    });
  }
  /**
   * sendNotice
   */
  static postTankResourceSmsEndpointSendNotice(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/sms/endpoint/send-notice"
    });
  }
  /**
   * sendOrder
   */
  static postTankResourceSmsEndpointSendOrder(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/sms/endpoint/send-order"
    });
  }
  /**
   * sendValidate
   */
  static postTankResourceSmsEndpointSendValidate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/sms/endpoint/send-validate"
    });
  }
  /**
   * validateMessage
   */
  static postTankResourceSmsEndpointValidateMessage(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-resource/sms/endpoint/validate-message"
    });
  }
}
class le {
  /**
   * 部门树查询接口
   * 树形结构
   */
  static getDeptTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/dept/tree"
    });
  }
}
class ce {
  /**
   * 登录页下拉数据源
   */
  static getTankSystemTenantAll(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/tenant/all"
    });
  }
  /**
   * 数据源配置
   * 传入datasource_id
   */
  static postTankSystemTenantDatasource(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/tenant/datasource"
    });
  }
  /**
   * 详情
   * 传入tenant
   */
  static getTankSystemTenantDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/tenant/detail"
    });
  }
  /**
   * 详情
   * 传入tenant
   */
  static getTankSystemTenantFindByName(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/tenant/find-by-name"
    });
  }
  /**
   * 配置信息
   * 传入domain
   */
  static getTankSystemTenantInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/tenant/info"
    });
  }
  /**
   * 分页
   * 传入tenant
   */
  static getTankSystemTenantList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/tenant/list"
    });
  }
  /**
   * 分页
   * 传入tenant
   */
  static getTankSystemTenantPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/tenant/page"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static postTankSystemTenantRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/tenant/remove"
    });
  }
  /**
   * 下拉数据源
   * 传入tenant
   */
  static getTankSystemTenantSelect(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/tenant/select"
    });
  }
  /**
   * 授权配置
   * 传入ids,accountNumber,expireTime
   */
  static postTankSystemTenantSetting(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/tenant/setting"
    });
  }
  /**
   * 新增或修改
   * 传入tenant
   */
  static postTankSystemTenantSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/tenant/submit"
    });
  }
}
class ue {
  /**
   * 获取认证信息
   */
  static getTankAuthOauthCallbackBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-auth/oauth/callback/{source}"
    });
  }
  /**
   * 获取认证信息
   */
  static headTankAuthOauthCallbackBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).head({
      ...e,
      url: "/tank-auth/oauth/callback/{source}"
    });
  }
  /**
   * 获取认证信息
   */
  static postTankAuthOauthCallbackBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-auth/oauth/callback/{source}"
    });
  }
  /**
   * 获取认证信息
   */
  static putTankAuthOauthCallbackBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).put({
      ...e,
      url: "/tank-auth/oauth/callback/{source}"
    });
  }
  /**
   * 获取认证信息
   */
  static deleteTankAuthOauthCallbackBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).delete({
      ...e,
      url: "/tank-auth/oauth/callback/{source}"
    });
  }
  /**
   * 获取认证信息
   */
  static optionsTankAuthOauthCallbackBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).options({
      ...e,
      url: "/tank-auth/oauth/callback/{source}"
    });
  }
  /**
   * 获取认证信息
   */
  static patchTankAuthOauthCallbackBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).patch({
      ...e,
      url: "/tank-auth/oauth/callback/{source}"
    });
  }
  /**
   * 续期令牌
   */
  static getTankAuthOauthRefreshBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-auth/oauth/refresh/{source}"
    });
  }
  /**
   * 续期令牌
   */
  static headTankAuthOauthRefreshBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).head({
      ...e,
      url: "/tank-auth/oauth/refresh/{source}"
    });
  }
  /**
   * 续期令牌
   */
  static postTankAuthOauthRefreshBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-auth/oauth/refresh/{source}"
    });
  }
  /**
   * 续期令牌
   */
  static putTankAuthOauthRefreshBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).put({
      ...e,
      url: "/tank-auth/oauth/refresh/{source}"
    });
  }
  /**
   * 续期令牌
   */
  static deleteTankAuthOauthRefreshBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).delete({
      ...e,
      url: "/tank-auth/oauth/refresh/{source}"
    });
  }
  /**
   * 续期令牌
   */
  static optionsTankAuthOauthRefreshBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).options({
      ...e,
      url: "/tank-auth/oauth/refresh/{source}"
    });
  }
  /**
   * 续期令牌
   */
  static patchTankAuthOauthRefreshBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).patch({
      ...e,
      url: "/tank-auth/oauth/refresh/{source}"
    });
  }
  /**
   * 授权完毕跳转
   */
  static getTankAuthOauthRenderBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-auth/oauth/render/{source}"
    });
  }
  /**
   * 授权完毕跳转
   */
  static headTankAuthOauthRenderBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).head({
      ...e,
      url: "/tank-auth/oauth/render/{source}"
    });
  }
  /**
   * 授权完毕跳转
   */
  static postTankAuthOauthRenderBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-auth/oauth/render/{source}"
    });
  }
  /**
   * 授权完毕跳转
   */
  static putTankAuthOauthRenderBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).put({
      ...e,
      url: "/tank-auth/oauth/render/{source}"
    });
  }
  /**
   * 授权完毕跳转
   */
  static deleteTankAuthOauthRenderBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).delete({
      ...e,
      url: "/tank-auth/oauth/render/{source}"
    });
  }
  /**
   * 授权完毕跳转
   */
  static optionsTankAuthOauthRenderBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).options({
      ...e,
      url: "/tank-auth/oauth/render/{source}"
    });
  }
  /**
   * 授权完毕跳转
   */
  static patchTankAuthOauthRenderBySource(e) {
    return ((e == null ? void 0 : e.client) ?? r).patch({
      ...e,
      url: "/tank-auth/oauth/render/{source}"
    });
  }
  /**
   * 撤销授权
   */
  static getTankAuthOauthRevokeBySourceByToken(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-auth/oauth/revoke/{source}/{token}"
    });
  }
  /**
   * 撤销授权
   */
  static headTankAuthOauthRevokeBySourceByToken(e) {
    return ((e == null ? void 0 : e.client) ?? r).head({
      ...e,
      url: "/tank-auth/oauth/revoke/{source}/{token}"
    });
  }
  /**
   * 撤销授权
   */
  static postTankAuthOauthRevokeBySourceByToken(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-auth/oauth/revoke/{source}/{token}"
    });
  }
  /**
   * 撤销授权
   */
  static putTankAuthOauthRevokeBySourceByToken(e) {
    return ((e == null ? void 0 : e.client) ?? r).put({
      ...e,
      url: "/tank-auth/oauth/revoke/{source}/{token}"
    });
  }
  /**
   * 撤销授权
   */
  static deleteTankAuthOauthRevokeBySourceByToken(e) {
    return ((e == null ? void 0 : e.client) ?? r).delete({
      ...e,
      url: "/tank-auth/oauth/revoke/{source}/{token}"
    });
  }
  /**
   * 撤销授权
   */
  static optionsTankAuthOauthRevokeBySourceByToken(e) {
    return ((e == null ? void 0 : e.client) ?? r).options({
      ...e,
      url: "/tank-auth/oauth/revoke/{source}/{token}"
    });
  }
  /**
   * 撤销授权
   */
  static patchTankAuthOauthRevokeBySourceByToken(e) {
    return ((e == null ? void 0 : e.client) ?? r).patch({
      ...e,
      url: "/tank-auth/oauth/revoke/{source}/{token}"
    });
  }
}
class me {
  /**
   * 详情
   * 传入topMenu
   */
  static getTankSystemTopmenuDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/topmenu/detail"
    });
  }
  /**
   * 顶部菜单配置
   * 传入topMenuId集合以及menuId集合
   */
  static postTankSystemTopmenuGrant(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/topmenu/grant"
    });
  }
  /**
   * 分页
   * 传入topMenu
   */
  static getTankSystemTopmenuList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-system/topmenu/list"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static postTankSystemTopmenuRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/topmenu/remove"
    });
  }
  /**
   * 新增
   * 传入topMenu
   */
  static postTankSystemTopmenuSave(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/topmenu/save"
    });
  }
  /**
   * 新增或修改
   * 传入topMenu
   */
  static postTankSystemTopmenuSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/topmenu/submit"
    });
  }
  /**
   * 修改
   * 传入topMenu
   */
  static postTankSystemTopmenuUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-system/topmenu/update"
    });
  }
}
class de {
  /**
   * 获取域名信息接口
   * 传入域名
   */
  static getDomainInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/domain/info"
    });
  }
  /**
   * 管理员权限更新
   */
  static postInitialAdmin(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/initial/admin"
    });
  }
  /**
   * 云仓租户初始化接口
   * 传入ycUserLoginDto
   */
  static postInitialTenant(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/initial/tenant"
    });
  }
  /**
   * 账号密码登录接口
   */
  static postUserLogin(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/user/login"
    });
  }
  /**
   * 用户菜单权限查询接口
   * 传入用户id
   */
  static getUserMenu(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/menu"
    });
  }
  /**
   * 用户密码修改接口
   * 传入ycUserUpdatePwdDto
   */
  static postUserPwdModify(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/user/pwd/modify"
    });
  }
  /**
   * 用户重置密码接口
   * 传入ycUserResetPwdDto
   */
  static postUserPwdReset(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/user/pwd/reset"
    });
  }
}
class ge {
  /**
   * 获取直属部门和员工接口
   */
  static getDeptUserChild(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/dept/user/child"
    });
  }
  /**
   * 全部用户查询接口
   * 树形结构
   */
  static getUserTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/tree"
    });
  }
}
class ke {
  /**
   * CRM - 角色列表查询接口V2
   */
  static getRoleInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v2/role/info"
    });
  }
  /**
   * CRM-用户创建接口-V2
   */
  static postUserInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v2/user/info"
    });
  }
  /**
   * @deprecated
   * 用户修改
   */
  static putUserInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).put({
      ...e,
      url: "/v2/user/info"
    });
  }
  /**
   * 企微/钉钉登录接口
   */
  static postUserLogin(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v2/user/login"
    });
  }
}
class ve {
  /**
   * 区域数查询
   * 传入关键字
   */
  static getAreaTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/area/tree"
    });
  }
  /**
   * 评论新增
   */
  static postCommentAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/comment/add"
    });
  }
  /**
   * 评论删除
   */
  static deleteCommentDelete(e) {
    return ((e == null ? void 0 : e.client) ?? r).delete({
      ...e,
      url: "/v1/comment/delete"
    });
  }
  /**
   * 评论列表
   */
  static getCommentList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/comment/list"
    });
  }
}
class ye {
  /**
   * 云仓全局配置查询
   */
  static getYcCommonConfig(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/yc/common/config"
    });
  }
  /**
   * 云仓全局配置查询
   */
  static getYcCommonConfigClear(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/yc/common/config/clear"
    });
  }
}
class Se {
  /**
   * 线索协作人添加
   */
  static postLeadsCollaboratorAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/collaborator/add"
    });
  }
  /**
   * 协作人列表
   */
  static getLeadsCollaboratorList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/collaborator/list"
    });
  }
  /**
   * 协作人删除
   */
  static deleteLeadsCollaboratorRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).delete({
      ...e,
      url: "/v1/leads/collaborator/remove"
    });
  }
  /**
   * 协作人变更
   */
  static postLeadsCollaboratorUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/collaborator/update"
    });
  }
}
class he {
  /**
   * 新增
   * 传入ycCrmContract
   */
  static postContractAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/contract/add"
    });
  }
  /**
   * 详情
   * 传入ycCrmContract
   */
  static getContractDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/contract/detail"
    });
  }
  /**
   * 分页
   * 传入ycCrmContract
   */
  static getContractList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/contract/list"
    });
  }
  /**
   * 分页
   * 传入ycCrmContract
   */
  static getContractPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/contract/page"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static postContractRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/contract/remove"
    });
  }
  /**
   * 新增或修改
   * 传入ycCrmContract
   */
  static postContractSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/contract/submit"
    });
  }
  /**
   * 修改
   * 传入ycCrmContract
   */
  static postContractUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/contract/update"
    });
  }
}
class Te {
  /**
   * 新增
   * 传入ycCrmDept
   */
  static postDepartmentAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/department/add"
    });
  }
  /**
   * 详情
   * 传入ycCrmDept
   */
  static getDepartmentDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/department/detail"
    });
  }
  /**
   * 分页
   * 传入ycCrmDept
   */
  static getDepartmentList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/department/list"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static deleteDepartmentRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).delete({
      ...e,
      url: "/v1/department/remove"
    });
  }
  /**
   * 新增或修改
   * 传入ycCrmDept
   */
  static postDepartmentSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/department/submit"
    });
  }
  /**
   * 分页
   * 传入ycCrmDept
   */
  static getDepartmentTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/department/tree"
    });
  }
  /**
   * 修改
   * 传入ycCrmDept
   */
  static postDepartmentUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/department/update"
    });
  }
  /**
   * 部门成员列表查询
   */
  static getDepartmentUserList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/department/user/list"
    });
  }
  /**
   * 部门成员列表查询
   */
  static getDepartmentWecomSync(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/department/wecom/sync"
    });
  }
}
class fe {
  /**
   * 新增
   * 传入ycCrmDraft
   */
  static postDraftAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/draft/add"
    });
  }
  /**
   * 详情
   * 传入ycCrmDraft
   */
  static getDraftDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/draft/detail"
    });
  }
  /**
   * 分页
   * 传入ycCrmDraft
   */
  static getDraftList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/draft/list"
    });
  }
  /**
   * 分页
   * 传入ycCrmDraft
   */
  static getDraftPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/draft/page"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static deleteDraftRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).delete({
      ...e,
      url: "/v1/draft/remove"
    });
  }
  /**
   * 新增或修改
   * 传入ycCrmDraft
   */
  static postDraftSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/draft/submit"
    });
  }
  /**
   * 修改
   * 传入ycCrmDraft
   */
  static postDraftUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/draft/update"
    });
  }
}
class De {
  /**
   * 新增
   * 传入ycCrmLeads
   */
  static postLeadsAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/add"
    });
  }
  /**
   * 标记星标线索
   */
  static getLeadsAsteriskPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/asterisk/page"
    });
  }
  /**
   * 线索收集
   */
  static postLeadsCollect(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/collect"
    });
  }
  /**
   * 联系人列表查询
   */
  static getLeadsContactsPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/contacts/page"
    });
  }
  /**
   * 详情
   * 传入ycCrmLeads
   */
  static getLeadsDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/detail"
    });
  }
  /**
   * 线索查重列表
   */
  static getLeadsDuplicateList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/duplicate/list"
    });
  }
  /**
   * 线索/客户列表分页导出
   */
  static getLeadsExport(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/export"
    });
  }
  /**
   * 附件列表查询
   */
  static getLeadsFileList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/file/list"
    });
  }
  /**
   * 加盟线索导入
   * 加盟线索导入
   */
  static postLeadsFranchiseImport(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/franchise/import"
    });
  }
  /**
   * 加盟线索导入模板下载
   * 加盟线索导入模板下载
   */
  static postLeadsFranchiseTemplateDownload(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/franchise/template/download"
    });
  }
  /**
   * 线索导入
   * 线索导入
   */
  static postLeadsImport(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/import"
    });
  }
  /**
   * 指派
   * 传入ids
   */
  static postLeadsOperateAssign(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/operate/assign"
    });
  }
  /**
   * 认领
   * 传入ids
   */
  static postLeadsOperateClaim(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/operate/claim"
    });
  }
  /**
   * @deprecated
   * 维护中客户状态变更
   * 传入ids
   */
  static postLeadsOperateCustomerStatusUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/operate/customer/status/update"
    });
  }
  /**
   * @deprecated
   * 客户开发申请
   * 传入ids
   */
  static postLeadsOperateDevelop(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/operate/develop"
    });
  }
  /**
   * 标记星标线索
   */
  static getLeadsOperateMarkAsterisk(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/operate/mark/asterisk"
    });
  }
  /**
   * @deprecated
   * 合同洽谈中
   * 传入ids
   */
  static postLeadsOperateNegotiation(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/operate/negotiation"
    });
  }
  /**
   * 管户人变更
   */
  static postLeadsOperateOwnerUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/operate/owner/update"
    });
  }
  /**
   * 退回线索
   */
  static postLeadsOperateReturn(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/operate/return"
    });
  }
  /**
   * 转有效线索
   * 传入ids
   */
  static postLeadsOperateScreen(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/operate/screen"
    });
  }
  /**
   * @deprecated
   * 转客户运维
   */
  static postLeadsOperateSiging(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/operate/siging"
    });
  }
  /**
   * 分页
   * 传入ycCrmLeads
   */
  static getLeadsPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/page"
    });
  }
  /**
   * 我的线索列表
   */
  static getLeadsPersonalList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/personal/list"
    });
  }
  /**
   * 线索刷新code
   */
  static getLeadsRefreshCode(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/refresh/code"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static deleteLeadsRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).delete({
      ...e,
      url: "/v1/leads/remove"
    });
  }
  /**
   * 线索导入模板下载
   * 线索导入模板下载
   */
  static postLeadsTemplateDownload(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/template/download"
    });
  }
  /**
   * 修改
   * 传入ycCrmLeads
   */
  static postLeadsUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/update"
    });
  }
}
class be {
  /**
   * 详情
   * 传入ycCrmOperateLog
   */
  static getOperateLogDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/operate/log/detail"
    });
  }
  /**
   * getLeader
   */
  static getOperateLogGetLeader(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/operate/log/getLeader"
    });
  }
  /**
   * 分页
   * 传入ycCrmOperateLog
   */
  static getOperateLogList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/operate/log/list"
    });
  }
  /**
   * 分页
   * 传入ycCrmOperateLog
   */
  static getOperateLogPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/operate/log/page"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static postOperateLogRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/operate/log/remove"
    });
  }
  /**
   * 新增
   * 传入ycCrmOperateLog
   */
  static postOperateLogSave(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/operate/log/save"
    });
  }
  /**
   * 新增或修改
   * 传入ycCrmOperateLog
   */
  static postOperateLogSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/operate/log/submit"
    });
  }
  /**
   * 修改
   * 传入ycCrmOperateLog
   */
  static postOperateLogUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/operate/log/update"
    });
  }
}
class Re {
  /**
   * 快递收费服务模板新增
   */
  static postLogisticsFeeMouldAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/logistics/fee/mould/add"
    });
  }
  /**
   * 快递收费模板详情查询
   */
  static getLogisticsFeeMouldDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/logistics/fee/mould/detail"
    });
  }
  /**
   * 快递收费模板列表查询
   */
  static getLogisticsFeeMouldList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/logistics/fee/mould/list"
    });
  }
  /**
   * 快递收费服务模板编辑
   */
  static postLogisticsFeeMouldUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/logistics/fee/mould/update"
    });
  }
  /**
   * 报价清单列表查询
   */
  static getQuotationList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/quotation/list"
    });
  }
  /**
   * 报价表刷新
   */
  static getQuotationRefresh(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/quotation/refresh"
    });
  }
  /**
   * 经营表原始数据同步到新表
   */
  static postRefreshDataManagement(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/refresh/data/management"
    });
  }
  /**
   * 仓储收费服务模板新增
   */
  static postWarehouseFeeMouldAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/warehouse/fee/mould/add"
    });
  }
  /**
   * 仓储收费服务模板详情查询
   */
  static getWarehouseFeeMouldDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/warehouse/fee/mould/detail"
    });
  }
  /**
   * 仓储收费服务模板列表
   */
  static getWarehouseFeeMouldList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/warehouse/fee/mould/list"
    });
  }
  /**
   * 仓储收费服务模板编辑
   */
  static postWarehouseFeeMouldUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/warehouse/fee/mould/update"
    });
  }
  /**
   * 仓库字段列表查询
   */
  static getWarehouseFieldList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/warehouse/field/list"
    });
  }
  /**
   * 客户经营信息新增
   */
  static postCustomerManagementAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v2/customer/management/add"
    });
  }
  /**
   * 客户经营信息详情查询
   */
  static getCustomerManagementDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v2/customer/management/detail"
    });
  }
  /**
   * 客户经营信息编辑
   */
  static postCustomerManagementUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v2/customer/management/update"
    });
  }
}
class Le {
  /**
   * 汇报新建
   */
  static postReportAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/report/add"
    });
  }
  /**
   * 汇报详情查询
   */
  static getReportDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/report/detail"
    });
  }
  /**
   * 汇报列表查询
   */
  static getReportList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/report/list"
    });
  }
}
class Ce {
  /**
   * 详情
   * 传入ycCrmShop
   */
  static getTankDeskYccrmshopDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-desk/yccrmshop/detail"
    });
  }
  /**
   * 分页
   * 传入ycCrmShop
   */
  static getTankDeskYccrmshopList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-desk/yccrmshop/list"
    });
  }
  /**
   * 分页
   * 传入ycCrmShop
   */
  static getTankDeskYccrmshopPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-desk/yccrmshop/page"
    });
  }
  /**
   * 逻辑删除
   * 传入ids
   */
  static postTankDeskYccrmshopRemove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-desk/yccrmshop/remove"
    });
  }
  /**
   * 新增
   * 传入ycCrmShop
   */
  static postTankDeskYccrmshopSave(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-desk/yccrmshop/save"
    });
  }
  /**
   * 新增或修改
   * 传入ycCrmShop
   */
  static postTankDeskYccrmshopSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-desk/yccrmshop/submit"
    });
  }
  /**
   * 修改
   * 传入ycCrmShop
   */
  static postTankDeskYccrmshopUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-desk/yccrmshop/update"
    });
  }
}
class se {
  /**
   * 商机状态分布查询
   */
  static getLeadsBusinessStateStat(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/business/state/stat"
    });
  }
  /**
   * 指标排行查询
   */
  static getRanking(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/ranking"
    });
  }
  /**
   * 销售简报-商机跟进情况-新增情况
   */
  static getSaleBulletinBusinessNew(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/sale/bulletin/business/new"
    });
  }
  /**
   * 销售简报-商机跟进情况-赢单/输单情况
   */
  static getSaleBulletinBusinessState(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/sale/bulletin/business/state"
    });
  }
  /**
   * 销售简报-部门线索数
   */
  static getSaleBulletinDeptStat(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/sale/bulletin/dept/stat"
    });
  }
  /**
   * 销售简报-专属客户
   */
  static getSaleBulletinExclusive(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/sale/bulletin/exclusive"
    });
  }
  /**
   * 销售简报-私海线索
   */
  static getSaleBulletinPrivate(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/sale/bulletin/private"
    });
  }
  /**
   * 销售漏斗数据查询
   */
  static getSaleFunnels(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/sale/funnels"
    });
  }
  /**
   * 用户角色树
   */
  static getUserRoleTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/role/tree"
    });
  }
}
class Oe {
  /**
   * 客户经营信息新增
   */
  static postCustomerManagementAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/customer/management/add"
    });
  }
  /**
   * 客户经营信息详情查询
   */
  static getCustomerManagementDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/customer/management/detail"
    });
  }
  /**
   * 客户经营信息下载
   */
  static getCustomerManagementDetailDownload(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/customer/management/detail/download"
    });
  }
  /**
   * 客户经营信息列表查询
   */
  static getCustomerManagementPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/customer/management/page"
    });
  }
  /**
   * 客户经营信息编辑
   */
  static postCustomerManagementUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/customer/management/update"
    });
  }
}
class Be {
  /**
   * 新增
   * 传入ycLeadsBusiness
   */
  static postLeadsBusinessAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/business/add"
    });
  }
  /**
   * 详情
   * 传入ycLeadsBusiness
   */
  static getLeadsBusinessDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/business/detail"
    });
  }
  /**
   * 商机赢单/输单判定
   */
  static getLeadsBusinessOperateDetermine(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/business/operate/determine"
    });
  }
  /**
   * 分页
   * 传入ycLeadsBusiness
   */
  static getLeadsBusinessPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/business/page"
    });
  }
  /**
   * 商机方案列表查询
   */
  static postLeadsBusinessProgramme(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/business/programme"
    });
  }
  /**
   * 修改
   * 传入ycLeadsBusiness
   */
  static postLeadsBusinessUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/business/update"
    });
  }
  /**
   * 商机赢单/输单判定
   */
  static getLeadsBusinessWinMsg(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/business/win/msg"
    });
  }
}
class we {
  /**
   * 新增
   * 传入ycLeadsFollowUp
   */
  static postLeadsFollowUpAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/follow/up/add"
    });
  }
  /**
   * 跟进评论新增
   */
  static postLeadsFollowUpCommentAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/leads/follow/up/comment/add"
    });
  }
  /**
   * 跟进评论删除
   */
  static deleteLeadsFollowUpCommentDelete(e) {
    return ((e == null ? void 0 : e.client) ?? r).delete({
      ...e,
      url: "/v1/leads/follow/up/comment/delete"
    });
  }
  /**
   * 跟进评论列表
   */
  static getLeadsFollowUpCommentList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/follow/up/comment/list"
    });
  }
  /**
   * 详情
   */
  static getLeadsFollowUpDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/follow/up/detail"
    });
  }
  /**
   * 分页
   * 传入ycLeadsFollowUp
   */
  static getLeadsFollowUpList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/follow/up/list"
    });
  }
  /**
   * @deprecated
   * 跟进记录统计
   */
  static getLeadsFollowUpStat(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/leads/follow/up/stat"
    });
  }
}
class Ae {
  /**
   * 业绩回款新增
   */
  static postPerformanceBillContentAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/performance/bill/content/add"
    });
  }
  /**
   * 账单回款审批
   */
  static postPerformanceBillContentApprove(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/performance/bill/content/approve"
    });
  }
  /**
   * 业绩回款撤销
   */
  static postPerformanceBillContentCallback(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/performance/bill/content/callback"
    });
  }
  /**
   * 业绩账单回款详情
   */
  static getPerformanceBillContentDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/performance/bill/content/detail"
    });
  }
  /**
   * 业绩账单回款记录查询
   */
  static getPerformanceBillContentPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/performance/bill/content/page"
    });
  }
  /**
   * 业绩回款编辑
   */
  static postPerformanceBillContentUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/performance/bill/content/update"
    });
  }
  /**
   * 业绩账单详情查询
   */
  static getPerformanceBillDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/performance/bill/detail"
    });
  }
  /**
   * 业绩账单列表查询
   */
  static getPerformanceBillPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/performance/bill/page"
    });
  }
}
class Ue {
  /**
   * 业绩统计报表查询
   */
  static postPerformanceBillReportExport(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/performance/bill/report/export"
    });
  }
  /**
   * 业绩统计报表查询
   */
  static postPerformanceBillReportPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/performance/bill/report/page"
    });
  }
  /**
   * 业绩账单统计查询
   */
  static postPerformanceBillStatInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/performance/bill/stat/info"
    });
  }
  /**
   * 业绩趋势统计查询
   */
  static postPerformanceBillStatList(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/performance/bill/stat/list"
    });
  }
  /**
   * 业绩账单回款超时情况统计
   */
  static postPerformanceBillStatTimeOut(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/performance/bill/stat/timeOut"
    });
  }
  /**
   * 业绩目标新增
   */
  static postPerformanceTargetYearAdd(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/performance/target/year/add"
    });
  }
  /**
   * 年度业绩详情查询
   */
  static getPerformanceTargetYearDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/performance/target/year/detail"
    });
  }
  /**
   * 年度业绩目标列表查询
   */
  static getPerformanceTargetYearList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/performance/target/year/list"
    });
  }
  /**
   * 业绩目标编辑
   */
  static postPerformanceTargetYearUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/performance/target/year/update"
    });
  }
}
class Pe {
  /**
   * 菜单树查询接口
   * 为空查询全部，0-前台（中台）菜单，1-后台菜单，2-PDA
   */
  static getMenuTree(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/menu/tree"
    });
  }
  /**
   * 超级管理员更换接口
   */
  static putRoleAdmin(e) {
    return ((e == null ? void 0 : e.client) ?? r).put({
      ...e,
      url: "/v1/role/admin"
    });
  }
  /**
   * 个人权限部门查询
   * 传入是否上浮标识
   */
  static getRoleDeptList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/role/dept/list"
    });
  }
  /**
   * 角色列表查询接口
   * 传入name
   */
  static getRoleInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/role/info"
    });
  }
  /**
   * 角色新增接口
   * 传入ycRoleDto
   */
  static postRoleInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/role/info"
    });
  }
  /**
   * 角色修改接口
   * 传入ycRoleIdDto
   */
  static putRoleInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).put({
      ...e,
      url: "/v1/role/info"
    });
  }
  /**
   * 角色删除接口
   * 传入ycRoleIdDto
   */
  static deleteRoleInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).delete({
      ...e,
      url: "/v1/role/info"
    });
  }
  /**
   * 角色菜单权限查询接口
   * 传入角色id
   */
  static getRoleMenu(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/role/menu"
    });
  }
  /**
   * 角色菜单权限修改接口
   * 传入角色id
   */
  static putRoleMenu(e) {
    return ((e == null ? void 0 : e.client) ?? r).put({
      ...e,
      url: "/v1/role/menu"
    });
  }
  /**
   * 角色配置用户接口
   * 传入roleUserDto
   */
  static postRoleUser(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/role/user"
    });
  }
  /**
   * 角色删除用户接口
   * 传入roleUserDto
   */
  static postRoleUserDel(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/role/user/del"
    });
  }
}
class Me {
  /**
   * 用户自定义表头设置查询
   */
  static getTitleConfigOne(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/title/config/one"
    });
  }
  /**
   * 用户自定义表头设置
   */
  static postTitleConfigUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/title/config/update"
    });
  }
}
class xe {
  /**
   * 成员线索/商机数量统计
   */
  static getSaleLeadsStatInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/sale/leads/stat/info"
    });
  }
  /**
   * 销售档案业绩查询
   */
  static getSalePerformanceInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/sale/performance/info"
    });
  }
  /**
   * 销售档案用户详情查询
   */
  static getSaleUserInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/sale/user/info"
    });
  }
  /**
   * 用户详情查询
   */
  static getSaleUserPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/sale/user/page"
    });
  }
  /**
   * CRM-用户全量部门数据
   */
  static getUserAllDept(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/all/dept"
    });
  }
  /**
   * CRM-用户全量部门数据(过滤)
   */
  static getUserAllDeptBlocked(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/all/dept/blocked"
    });
  }
  /**
   * CRM-指派用户列表查询接口
   */
  static getUserAssignList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/assign/list"
    });
  }
  /**
   * CRM-用户电子名片
   */
  static postUserBusinessCard(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/user/business/card"
    });
  }
  /**
   * @deprecated
   * CRM-用户可查询部门用户数据
   */
  static getUserBusinessQueryData(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/business/query/data"
    });
  }
  /**
   * 用户缓存清除
   */
  static getUserCacheClear(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/cache/clear"
    });
  }
  /**
   * 钉钉表详情数据同步
   */
  static getUserDingtalkDetailSync(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/dingtalk/detail/sync"
    });
  }
  /**
   * 用户导出
   */
  static getUserExport(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/export"
    });
  }
  /**
   * 用户详情查询
   */
  static getUserInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/info"
    });
  }
  /**
   * @deprecated
   * 用户创建接口
   */
  static postUserInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/user/info"
    });
  }
  /**
   * @deprecated
   * 用户修改
   */
  static putUserInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).put({
      ...e,
      url: "/v1/user/info"
    });
  }
  /**
   * 用户删除
   */
  static deleteUserInfo(e) {
    return ((e == null ? void 0 : e.client) ?? r).delete({
      ...e,
      url: "/v1/user/info"
    });
  }
  /**
   * 用户列表查询
   */
  static getUserList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/list"
    });
  }
  /**
   * 无指定角色的用户列表查询
   */
  static getUserNoroleList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/norole/list"
    });
  }
  /**
   * CRM用户动作统一收集接口
   */
  static postUserOperateCollect(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/user/operate/collect"
    });
  }
  /**
   * 用户密码批量重置
   */
  static postUserPwdUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/v1/user/pwd/update"
    });
  }
  /**
   * 用户所属角色信息列表
   */
  static getUserRoleList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/role/list"
    });
  }
  /**
   * unionId信息同步
   */
  static getUserUnionSync(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/user/union/sync"
    });
  }
}
class Ee {
  /**
   * BI指标分页查询
   */
  static getBiIndexPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/bi/index/page"
    });
  }
  /**
   * CRM-BI定值指标数据分页查询
   */
  static getBiReportFixedValueIndexPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/bi/report/fixedValue/index/page"
    });
  }
  /**
   * CRM-BI实时指标数据分页查询
   */
  static getBiReportRealTimeIndexPage(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/v1/bi/report/realTime/index/page"
    });
  }
}
class Ye {
  /**
   * 详情
   * 传入ycWecomCorpConfig
   */
  static getTankDeskYcwecomcorpconfigDetail(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-desk/ycwecomcorpconfig/detail"
    });
  }
  /**
   * 新增或修改
   * 传入ycWecomCorpConfig
   */
  static postTankDeskYcwecomcorpconfigGetToken(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-desk/ycwecomcorpconfig/getToken"
    });
  }
  /**
   * 新增或修改
   * 传入ycWecomCorpConfig
   */
  static postTankDeskYcwecomcorpconfigGetUser(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-desk/ycwecomcorpconfig/getUser"
    });
  }
  /**
   * 分页
   * 传入ycWecomCorpConfig
   */
  static getTankDeskYcwecomcorpconfigList(e) {
    return ((e == null ? void 0 : e.client) ?? r).get({
      ...e,
      url: "/tank-desk/ycwecomcorpconfig/list"
    });
  }
  /**
   * 新增
   * 传入ycWecomCorpConfig
   */
  static postTankDeskYcwecomcorpconfigSave(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-desk/ycwecomcorpconfig/save"
    });
  }
  /**
   * 新增或修改
   * 传入ycWecomCorpConfig
   */
  static postTankDeskYcwecomcorpconfigSubmit(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-desk/ycwecomcorpconfig/submit"
    });
  }
  /**
   * 修改
   * 传入ycWecomCorpConfig
   */
  static postTankDeskYcwecomcorpconfigUpdate(e) {
    return ((e == null ? void 0 : e.client) ?? r).post({
      ...e,
      url: "/tank-desk/ycwecomcorpconfig/update"
    });
  }
}
export {
  E as ApiScopeService,
  Y as AttachService,
  F as BackgroundCommonExportService,
  z as CenterLoginSmsService,
  I as ClientManageService,
  W as CrmParentOwnerNoAssService,
  j as CrmXxlJobService,
  N as CrmYcMsgReadService,
  $ as DataConfigurationService,
  q as DataScopeService,
  Q as DeptService,
  G as DictBizService,
  V as DictService,
  J as EmpowerService,
  K as MailEndpointService,
  X as MenuService,
  H as NoticeService,
  Z as OssEndpointService,
  _ as OssObjectStorageService,
  n as ParamManageService,
  i as PostService,
  p as PublicAppService,
  o as PublicDictService,
  ee as QcyService,
  re as RegionService,
  te as RoleService,
  ae as SmsEndpointService,
  le as SystemOpenService,
  ce as TenantService,
  ue as ThirdPartyLoginsService,
  me as TopMenuService,
  de as UserLoginService,
  ge as UserOpenApiControllerService,
  ke as V2Service,
  ve as YcAreaConfigService,
  ye as YcCommonConfigService,
  Se as YcCrmCollaboratorService,
  he as YcCrmContractService,
  Te as YcCrmDeptService,
  fe as YcCrmDraftService,
  De as YcCrmLeadsService,
  be as YcCrmOperateLogService,
  Re as YcCrmQuotationService,
  Le as YcCrmReportService,
  Ce as YcCrmShopService,
  se as YcCrmStatControllerService,
  Oe as YcCustomerManagementService,
  Be as YcLeadsBusinessService,
  we as YcLeadsFollowUpService,
  Ae as YcPerformanceBillControllerService,
  Ue as YcPerformanceControllerService,
  Pe as YcRoleService,
  Me as YcTitleConfigService,
  xe as YcUserService,
  r as client,
  Ee as 云仓CrmBi看板Service,
  Ye as 企微企业信息表接口Service
};
