<template>
  <a-config-provider
    :get-popup-container="getPopupContainer"
    :locale="locale"
    @click="getPopupContainer"
  >
    <ys-provider :api="providerApi" :search-form="searchForm">
      <div id="app" class="h-full overflow-auto">
        <router-view />
      </div>
    </ys-provider>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

import { providerApi } from './config/providerApi'

export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
      providerApi,
      searchForm: {
        controlsPerRow: {
          xs: 2,
          sm: 3,
          md: 3,
          lg: 4,
          xl: 5,
        },
      },
    }
  },
  created() {
    window.addEventListener('resize', (e) => {
      this.$store.commit('SET_SCREEN_INFO', {
        clientWidth: e.target.innerWidth,
        clientHeight: e.target.innerHeight,
      })
    })
  },
  methods: {
    getPopupContainer(el, dialogContext) {
      if (dialogContext) {
        return dialogContext.getDialogWrap()
      }
      return el?.parentNode || document.body
    },
  },
}
</script>
