/**
 * 文件工具函数
 */
import mime from 'mime'

import { isImage, isUrl } from './test'

/**
 * @description: 获取文件后缀 https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 * @param {File} file // 文件对象
 * @return {String} // jpeg png 等，不携带“.”
 */
export const getExtension = (file) => {
  return mime.getExtension(file.type)
}
/**
 * @description: 是否为有效的文件格式
 * @param {File} file // 文件对象 File { type: 'image/png' }
 * @param {String} accept //  接受上传的文件类型, 详见 input accept Attribute https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
 * @return {Boolean} true false
 */
export const validFileType = (file, accept) => {
  if (['', '*', '*/*'].includes(accept)) {
    return true
  }

  const acceptArr = accept.split(',')
  const mimeType = file.type

  const arr = ['audio', 'image', 'video']
  let isValidate = false
  for (let i = 0; i < arr.length; i++) {
    // image/* video/* audio/*
    if (acceptArr.includes(`${arr[i]}/*`) && !!mimeType && mimeType.indexOf(`${arr[i]}/`) === 0) {
      isValidate = true
      break
    }
  }
  const ext = mime.getExtension(mimeType) // jpeg png
  return acceptArr.includes(`.${ext}`) || acceptArr.includes(mimeType) || isValidate
}
/**
 * @description: 是否满足文件大小限制
 * @param {File} file // 文件对象
 * @param {Number} limitSize 限制的文件大小，单位为M。0 表示大小不受限
 * @return {Boolean} true满足 false不满足
 */
export const validFileSize = (file, limitSize) => {
  return file.size / 1024 / 1024 <= limitSize || limitSize === 0
}
/**
 * @description: 通过链接下载图片
 * @param {String} src 需要下载的图片链接
 * @param {String} name 图片名称
 * @return {Promise<{img: Object;}>[]} img实例
 */
export const downloadImage = (src, name) => {
  return new Promise((resolve, reject) => {
    if (!isImage(src)) {
      reject('不是合法的图片链接无法下载。链接需携带".png|.jpg"等后缀')
      return
    }
    name = name || src.split('/').slice(-1).toString() // 通过链接获取文件名
    const img = new Image()
    img.setAttribute('crossOrigin', 'anonymous') // 解决跨域
    img.src = src
    img.name = decodeURIComponent(name)
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      const context = canvas.getContext('2d')
      context.drawImage(img, 0, 0, img.width, img.height)
      const url = canvas.toDataURL('image/png')
      const a = document.createElement('a')
      a.href = url
      a.download = decodeURIComponent(name)

      const event = new MouseEvent('click')
      a.dispatchEvent(event)
      resolve(img)
    }
    img.onerror = (err) => {
      reject(err)
    }
  })
}
/**
 * @description: 下载文件
 * @param {String} target 需要下载的文件源，可以是Blob或者完整的文件url
 * @param {String} filename 文件名，如果target是Blob类型，则需要通过外部传入文件名
 * @return {Promise} { target, filename, url }
 */
export const downloadFile = (target, filename) => {
  return new Promise((resolve, reject) => {
    try {
      filename = !filename && isUrl(target) ? target.split('/').slice(-1).toString() : filename
      filename = decodeURIComponent(filename)

      if (isUrl(target) && isImage(target)) {
        downloadImage(target, filename)
          .then((img) => resolve(img))
          .catch(reject)
      } else {
        const url = isUrl(target) ? target : window.URL.createObjectURL(new Blob([target]))
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        const event = new MouseEvent('click')
        a.dispatchEvent(event)

        !isUrl(target) && window.URL.revokeObjectURL(url) // 释放由 createObjectURL 创建的 object URL，释放内存，提高性能
        resolve({ target, filename, url })
      }
    } catch (error) {
      reject(error)
    }
  })
}
